export default function InputElement({
  radius,
  name,
  type = "text",
  value,
  onChange,
  placeholder,
  required = false,
  children,
}) {
  let parsedRadius = "rounded";

  if (radius === "sm") parsedRadius = "rounded-sm";
  if (radius === "lg") parsedRadius = "rounded-lg";
  if (radius === "full") parsedRadius = "rounded-full";

  return (
    <input
      name={name}
      onChange={(e) => (onChange ? onChange(e.target) : null)}
      placeholder={placeholder}
      required={required}
      value={value}
      type={type}
      className={`${parsedRadius} outline-none p-3 text-gray-300 font-medium bg-gray-700 border border-opacity-20 transition ease-linear hover:border-green-500 focus:border-green-400`}
    >
      {children}
    </input>
  );
}
