import { Switch, Route } from "react-router-dom";

// Pages
import Home from "../../pages/Home/Home";
import Codes from "../../pages/Codes/Codes";
import FavoriteCodes from "../../pages/FavoriteCodes/FavoriteCodes";
import Search from "../../pages/Search/Search";
import NotFound from "../../pages/NotFound/NotFound";

export default function Content() {
  return (
    <div className="flex-1 overflow-auto">
      <Switch>
        <Route path="/search" component={Search}></Route>
        <Route path="/codes" component={Codes}></Route>
        <Route
          path="/user/@me/favorite-codes"
          component={FavoriteCodes}
        ></Route>
        <Route exact path="/" component={Home}></Route>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}
