import { NavLink } from "react-router-dom";

export default function Sidebar() {
  return (
    <aside className="w-64 flex-shrink-0 border-r border-opacity-30 px-5 py-6">
      <nav className="h-full">
        <div className="mb-4 w-full flex justify-center">
          <img
            src="/codax_logo.svg"
            alt="Codax Logo"
            width="60px"
            height="60px"
          ></img>
        </div>
        <ul className="flex flex-col space-y-1">
          <li>
            <NavLink
              exact
              activeClassName="text-green-500 bg-gray-800"
              to="/"
              className="font-medium rounded-lg p-3 flex items-center transition ease-linear hover:bg-gray-700"
            >
              <span>
                <i className="ml-1 mr-5 fas fa-home" />
              </span>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="text-green-500 bg-gray-800"
              to="/codes"
              className="font-medium rounded-lg p-3 flex items-center transition ease-linear hover:bg-gray-700"
            >
              <span>
                <i className="ml-1 mr-5 fas fa-code" />
              </span>
              Codes
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              activeClassName="text-green-500 bg-gray-800"
              to="/user/@me/favorite-codes"
              className="font-medium rounded-lg p-3 flex items-center transition ease-linear hover:bg-gray-700"
            >
              <span>
                <i className="ml-1 mr-5 fas fa-star" />
              </span>
              Favorite Codes
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
}
