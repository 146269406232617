import LinkElement from "../../ui/LinkElement/LinkElement";

export default function Home() {
  return (
    <div className="container">
      <div className="relative flex flex-col justify-between m-5 w-96 max-h-52- bg-gray-900 rounded-2xl">
        <div className="mt-2 ml-3 overflow-hidden">
          <span className="text-white font-semibold text-lg cursor-default">Preview</span>
          <p className="mt-2 mr-2 mb-1 text-sm text-gray-300">
            Hello guest, this is a preview site of Codax. Lorem ipsum dolor sit
            amet, consectetur adipisicing elit. Earum id nobis assumenda laborum
            sint commodi delectus necessitatibus aliquam aperiam provident?
          </p>
        </div>
        <div className="absolute bottom-3 left-3">
          <span className="text-sm text-gray-400 hover:text-gray-300 transition font-semibold cursor-pointer">
            30/09/2021
          </span>
        </div>
        <div className="flex justify-end mb-3 mr-3">
          <LinkElement noStyle to="/users/crawl">
            <img
              src="https://cdn.discordapp.com/avatars/748366237788012695/55d85f68b2d8ae3c98a178e2754ef3a5.png"
              alt="User Avatar"
              width="35px"
              height="35px"
              className="rounded-full border border-opacity-20 transition hover:border-green-500"
            ></img>
          </LinkElement>
        </div>
      </div>
    </div>
  );
}
