import ButtonElement from "../../ui/ButtonElement/ButtonElement";
import InputElement from "../../ui/InputElement/InputElement";
import LinkElement from "../../ui/LinkElement/LinkElement";

export default function Navbar() {
  return (
    <div className="pl-4 pr-7 h-20 flex items-center justify-between border-b border-opacity-20">
      <div className="space-x-2">
        <LinkElement to="/users/@me/friends">Friends</LinkElement>
        <LinkElement to="/users/@me/messages">Messages</LinkElement>
        <LinkElement to="/users/@me/mail">Mail</LinkElement>
      </div>
      <div className="flex items-center">
        <div className="mr-4">
          <form action="/search" method="get">
            <InputElement
              name="vl"
              radius="full"
              type="search"
              placeholder="Type in here to search..."
              required
            />
            <ButtonElement hidden type="submit" />
          </form>
        </div>
        <div className="flex">
          <ButtonElement size="sm">Login</ButtonElement>
        </div>
      </div>
    </div>
  );
}
