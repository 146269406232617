import { Link } from "react-router-dom";

// TODO: add icons
export default function LinkElement({
  noStyle = false,
  radius,
  size,
  to = "/",
  children,
}) {
  let parsedSize = "p-1";
  let parsedRadius = "rounded";

  if (size === "sm") parsedSize = "px-1";
  else if (size === "lg") parsedSize = "p-2";

  if (radius === "sm") parsedRadius = "rounded-sm";
  else if (radius === "lg") parsedRadius = "rounded-lg";
  else if (radius === "full") parsedRadius = "rounded-full";

  return noStyle ? (
    <Link to={to}>{children}</Link>
  ) : (
    <Link
      to={to}
      className={`${parsedSize} ${parsedRadius} group rounded-sm transition font-semibold text-gray-300 hover:text-gray-100 bg-gray-700 hover:bg-gray-600 hover:underline`}
    >
      {children}
      <i className="ml-2 text-green-400 group-hover:text-green-300 text-sm fas fa-link"></i>
    </Link>
  );
}
