export default function ButtonElement({
  hidden,
  size,
  type = "button",
  children,
}) {
  let parsedSize = "py-4 px-5";

  if (size === "sm") parsedSize = "py-2 px-5";
  else if (size === "lg") parsedSize = "py-6 px-5";

  return hidden ? (
    <button hidden type={type}></button>
  ) : (
    <button
      type={type}
      className={
        parsedSize +
        " rounded transition duration-300 font-bold bg-green-400 hover:bg-green-500"
      }
    >
      {children}
    </button>
  );
}
