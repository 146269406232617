import AElement from "../../ui/AElement/AElement";

export default function Footer() {
  return (
    <div className="h-16 pl-4 pr-7 flex justify-between items-center border-t border-opacity-10 font-medium">
      <div>&copy; 2021 - All rights reserved</div>
      <div className="flex space-x-2">
        <AElement href="https://github.com/Proxity-S/Codax">
          <i className="text-sm fab fa-github"></i> Github
        </AElement>
        <AElement href="https://discord.gg/MVJKcYMZwR">
          <i className="text-sm fab fa-discord"></i> Discord
        </AElement>
      </div>
    </div>
  );
}
