export default function AElement({ href, children }) {
  return (
    <div>
      <a
        href={href}
        className="group rounded-sm p-1 transition font-semibold text-gray-300 hover:text-gray-100 bg-gray-700 hover:bg-gray-600 hover:underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        <i className="ml-2 text-green-400 group-hover:text-green-300 text-sm fas fa-external-link-alt"></i>
      </a>
    </div>
  );
}
